.c-beneficios {
    background: transparentize($secondary-color, 0.96);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
        @include mobile {
            padding-top: 100px;
        }
    }

    &__content {
        h2 {
            color: $secondary-color;
            font-weight: 700;

            z-index: 9;
            margin-bottom: 40px;
            font-size: 42px;

            @include mobile {
                font-size: 24px;
            }
        }
    }
    &__list {
        .slick-track {
            display: flex;
            justify-content: space-between;
            min-height: 430px;

            @include mobile {
                justify-content: center;
            }

            .slick-slide {

                > div {
                    display: flex;
                    justify-content: center;
                }
            }
        }
        &__item {
            background-color: white;
            border-radius: 3px;
            box-shadow: 0px 2px 6px rgba(148, 150, 154, 0.2);
            padding: 32px;
            max-width: 350px;
            min-height: 420px;

            &__image {
                width: 60px;
                margin: 0 auto 24px;

                img {
                    width: 100%;
                }
            }
        }
    }

    &__sublist {
        font-size: 16px;

        li {
            color: $secondary-color;
            display: flex;
            align-items: baseline;
            font-family: $font-secondary;
            
            svg {
                color: $primary-color;
                font-size: 12px;
                margin-right: 8px;
            }
        }
        
        li:not(:last-child) {
            margin-bottom: 8px;
        }
    }
}