.c-avaliacoes {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        h2 {
            color: $secondary-color;
            font-weight: 700;

            z-index: 9;
            margin-bottom: 40px;
            font-size: 42px;

            @include mobile {
                font-size: 24px;
            }
        }
    }

    &__slide {
        background: transparentize($secondary-color, 0.96);
        padding: 24px;
        min-height: 300px;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
        font-size: 16px;

        svg {
            color: $primary-color;
        }

        span {
            font-style: italic;
            opacity: 0.6;
        }
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        margin: 0 16px;
    }

    .slick-next {
        @include mobile {
            right: 0;
        }
    }

    .slick-prev {
        @include mobile {
            left: 0;
        }
    }

    .slick-prev:before, .slick-next:before {
        color: $secondary-color;
    }
}