.c-contato {
    height: 100%;
    background: transparentize($secondary-color, 0.96);

    @include desktop {
        display: flex;
    }

    &__image {
        background: url('../../assets/images/vintage-pink-telephone-arrangement.png')no-repeat;
        background-position: center;
        background-size: contain;
        width: 70%;
        position: relative;
        z-index: -9;

        &__overlay {
            @include imageOverlay;
        }

        &--mb {
            position: relative;
            margin-top: 126px;
            img {
                width: 100%;
            }
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    &__content {
        h2 {
            color: $secondary-color;
            font-weight: 700;
            margin-right: -100px;
            z-index: 9;
            margin-bottom: 40px;
            font-size: 42px;

            @include mobile {
                font-size: 28px;
                margin-right: 0;
                margin-top: 32px;
                margin-bottom: 32px;
            }
        }

        &__text {

            h6 {
                color: $primary-color;
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 32px;

                @include mobile {
                    font-size: 20px;
                }
                
                span {
                    color: $secondary-color;
                    font-weight: 400;
                    display: block;
                    font-size: 16px;
                    margin-top: 8px;
                    @include mobile {
                        font-size: 14px;
                    }
                }
            }

            a, p {
                display: flex;
                align-items: center;
                margin-bottom: 24px;

                span {
                    font-size: 16px;
                    color: $secondary-color;
                    font-weight: 500;

                    span {
                        font-weight: 400;
                    }
                }
            }

            a {

                svg {
                    color: $whatsapp;
                }
                span {
                    &:hover {
                        border-bottom: 1px solid $primary-color;
                    }
                }
            }

            p:last-child {
                align-items: flex-start;
            }

            svg {
                color: $primary-color;
                font-size: 32px;
                margin-right: 10px;

                @include mobile {
                    font-size: 24px;
                }
            }
        }
    }
}