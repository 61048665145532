.c-home {
    background: url('../../assets/images/tender-african-woman-smiling-enjoying-massage-with-closed-eyes-in-spa-resort.png') no-repeat;
    background-position: bottom;
    background-size: cover;
    height: 100%;
    position: relative;

    &__content {
        @include imageOverlay;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary-color;

        .container {
            @include mobile {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-top: 140px;
                padding-bottom: 24px;
            }
        }

        h1 {
            font-weight: 700;
            text-align: right;
            display: flex;
            text-transform: lowercase;
            font-size: 58px;

            @include mobile {
                display: flex;
                justify-content: flex-end;
                margin-top: 0;
                font-size: 42px;
                color: $default-color;
            }
        }

        h2 {
            text-align: center;
            margin-top: 100px;
            padding-bottom: 16px;
            color: $primary-color;
            border-bottom: 1px solid $primary-color;

            @include mobile {
                font-size: 20px;
                margin-top: 0;
            }
        }

        &__text {
            display: flex;
            justify-content: center;
            margin-top: 100px;
            text-transform: uppercase;
            font-size: 20px;
            background-color: #ffffff99;
            border-radius: 3px;
            font-family: $font-secondary;

            @include mobile {
                flex-wrap: wrap;
                margin-top: 0;
            }

            li {
                padding: 24px;

                @include mobile {
                    padding: 16px;
                }
            }
        }
    }
}