@mixin imageOverlay {
    background-color: rgba(255, 255, 255, 0.534);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include mobile {
        background-color: rgba(255, 255, 255, 0.58);
    }
}

@mixin desktop {
    @media screen and (min-width: 768px) {
        @content
    }
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content
    }
}