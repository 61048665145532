.c-massagens {
    height: 100%;
    display: flex;

    &__image {
        background: url('../../assets/images/young-caucasian-woman-getting-anti-age-massage.png') no-repeat;
        background-position: center;
        background-size: contain;
        width: 70%;
        position: relative;
        z-index: -9;

        @include mobile {
            width: 100%;
            height: 100%;
            background-size: cover;
            position: absolute;
            background: white;
        }

        &__overlay {
            @include imageOverlay;
        }

        &--mb {
            margin-top: 124px;
            img {
                width: 100%;
            }
        }
    }

    .container {
        display: flex;
        align-items: center;

        @include mobile {
            padding-top: 140px;
        }
    }

    &__content {

        @include mobile {
            margin-top: 32px;
        }

        h2 {
            color: $secondary-color;
            font-weight: 700;
            margin-left: -20%;
            z-index: 9;
            margin-bottom: 40px;
            font-size: 42px;

            @include mobile {
                margin-left: 0;
                font-size: 24px;
                margin-bottom: 24px;
            }
        }
    }

    &__list {
        color: $primary-color;
        display: flex;
        flex-wrap: wrap;
        text-transform: uppercase;

        @include mobile {
            justify-content: center;
        }

        li {
            padding: 16px;
            background: transparentize($secondary-color, 0.96);
            margin: 8px;
            width: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 3px;

            @include mobile {
                background: transparentize($secondary-color, 0.96);
                width: 45%;
                padding: 8px;
            }

            h6 {
                font-size: 20px;
                margin-bottom: 0;

                @include mobile {
                    font-size: 14px;
                    line-height: 20px
                }

                span {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
}